import "./home.style.scss";
import LogoHome from "../../../assets/image/logo-home.svg";

function Home() {
  return (
    <div className="home-comp">
      <div>
        <img className="logo-home" src={LogoHome} alt="" />
      </div>

      <div className="title-home font-archivo">
        <p>ELECTRICAL SERVICE</p>
      </div>
    </div>
  );
}

export default Home;
