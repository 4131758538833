import "./footer.style.scss";
import FbIcon from "../../../assets/image/fb-icon.svg";

function Footer() {
  return (
    <div className="footer-comp">
      <div className="footer-item">
        <a href="https://www.facebook.com/share/qBPkDPadPbVv9wEY/?mibextid=LQQJ4d">
          <img className="fb-icon" src={FbIcon} alt="" />
          <h3>New Day</h3>
        </a>
        <p className="f8-color text-16">info@new-day.ca</p>
        <p className="white-color text-16">
          Hotline: 1 (877)-306-2516 (Toll Free) <br /> (403)-995-7636
        </p>
        <p className="white-color text-16">
          Address: 102 - 708 11th Ave SW Calgary, AB T2R 0E4
        </p>
        <p style={{ color: "#9AACBC" }} className="text-14">
          Copyright © 2019 New Day - All Rights Reserved.
        </p>
      </div>
    </div>
  );
}

export default Footer;
