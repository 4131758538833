import "./service.style.scss";
import ServiceImg from "../../../assets/image/service-img.svg";

function Service() {
  return (
    <div className="service-comp">
      <div className="service">
        <div className="detail-img">
          <img src={ServiceImg} alt="" />
        </div>
        <div className="detail">
          <div className="description">
            <p className="title-detail font-archivo">OUR SERVICE</p>
            <p className="info-detail">
              New Construction, Renovations, Retrofits, LED Upgrades, EV
              Charging, Maintenance, Repair and any of your other electrical
              needs. Our team is experienced in Commercial, Industrial and
              Residential applications.
            </p>
          </div>
        </div>
      </div>

      {/* mobile */}
      <div className="service-mobile">
        <div className="detail">
          <p className="title-detail font-archivo">OUR SERVICE</p>
          <img src={ServiceImg} alt="" />
          <p className="info-detail">
            New Construction, Renovations, Retrofits, LED Upgrades, EV Charging,
            Maintenance, Repair and any of your other electrical needs. Our team
            is experienced in Commercial, Industrial and Residential
            applications.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Service;
