import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import NewDay from './app/page/newday/newday.component';
import Navbar from './app/core/navbar/navbar.component';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="/" element={<NewDay />}>
            <Route path="/home" element={<Navbar />}/>
          </Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
