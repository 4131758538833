import "./contact.style.scss";

function Contact() {
  return (
    <div className="contact-comp">
      <h2 className="title font-archivo">CONTACT US</h2>
      <div className="form-register">
        <input type="text" placeholder="Name" />
        <input type="text" placeholder="Email*" />
        <input type="text" placeholder="Phone" />
        <input type="text" placeholder="Address (Street, City, ZIp Code)" />
        <textarea name="" id="" rows={6}></textarea>
        <button className="sub-btn">SEND</button>
      </div>

      <div className="term">
        <div className="term-action">
          This site is protected by reCAPTCHA and the Google&nbsp;
          <p className="weight-text">Privacy Policy </p> &nbsp;and&nbsp;
          <p className="weight-text">Terms of Service </p> &nbsp; apply.
        </div>

        <div className="term-info">
          We stay in constant communication with our customers until the job is
          done. To get a free quote, or if you have questions or special
          requests, just drop us a line.
        </div>
      </div>
    </div>
  );
}

export default Contact;
